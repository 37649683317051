// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contribute-js": () => import("./../../../src/pages/contribute.js" /* webpackChunkName: "component---src-pages-contribute-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-organization-js": () => import("./../../../src/templates/organization.js" /* webpackChunkName: "component---src-templates-organization-js" */),
  "component---src-templates-organizations-js": () => import("./../../../src/templates/organizations.js" /* webpackChunkName: "component---src-templates-organizations-js" */)
}

